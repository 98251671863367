import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33e52e84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "m_news_page" }
const _hoisted_2 = {
  key: 0,
  id: "m_news_page_p_content"
}
const _hoisted_3 = {
  key: 1,
  id: "m_news_page_img_list"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  id: "m_news_page_img_single"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 3,
  id: "m_news_page_img_both"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 4 }
const _hoisted_10 = {
  id: "m_news_page_video",
  controls: "",
  name: "media"
}
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (item.type == 'p')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: `p_${index}_${j}`
                }, _toDisplayString(content), 1))
              }), 128))
            ]))
          : (item.type == 'img_list')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                  return (_openBlock(), _createElementBlock("img", {
                    key: `img_list_${index}_${j}`,
                    src: content,
                    id: "m_img_item"
                  }, null, 8, _hoisted_4))
                }), 128))
              ]))
            : (item.type == 'img')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                    return (_openBlock(), _createElementBlock("img", {
                      key: `img_${index}_${j}`,
                      src: content,
                      id: "m_img_single"
                    }, null, 8, _hoisted_6))
                  }), 128))
                ]))
              : (item.type == 'img_both')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (content, j) => {
                      return (_openBlock(), _createElementBlock("img", {
                        key: `img_${index}_${j}`,
                        src: content,
                        id: "m_img_item"
                      }, null, 8, _hoisted_8))
                    }), 128))
                  ]))
                : (item.type == 'video')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("video", _hoisted_10, [
                        _createElementVNode("source", {
                          src: item.videoUrl,
                          type: "video/mp4"
                        }, null, 8, _hoisted_11)
                      ])
                    ]))
                  : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}