
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {};
  },
})
export default class JoinusHeader extends Vue {}
